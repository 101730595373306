import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {WorkspaceType} from "@/dto/auth/Workspace";

export default class CreateCustomSignableDocumentPayload {
    file: FileMetaDTO | null = null;
    title: string | null = null;
    signers: SignerProtoDTO[] = [];
}

export class LabelProtoDTO {
    type: SigningLabelType | null = null;
    pageNumber: number | null = 1;
    relativePosX: number | null = 0;
    relativePosY: number | null = 0;
    relativeHeight: number | null = 0.07;
    companyAnonymousRole: string | null = null;
}

export class SignerProtoDTO {
    id: number | null = null;
    type: WorkspaceType | null = null;
    order: number | null = null;
    labels: LabelProtoDTO[] = [];
}

export enum SigningLabelType{
    SIGNATURE = "SIGNATURE",
    DATE = "DATE",
    NAME = "NAME",
    PRINT_NAME = "PRINT_NAME",
}