import CreateCustomSignableDocumentPayload, {LabelProtoDTO, SignerProtoDTO, SigningLabelType} from '@/dto/signature/CreateCustomSignableDocumentPayload';
import ProfileDTO from '@/dto/profile/ProfileDTO';

export const SIGNER_COLOR_POOL: string[] = [
    '#DDA866', '#D477C6', '#6ECBD5', '#DCDE2C', '#7449E9',
    '#E9497C', '#83E343', '#47D99E', '#B73DD3', '#AD650E'
];

export const LABEL_TYPES = [
    {value: 'SIGNATURE', label: 'Signature'},
    {value: 'DATE', label: 'Date'}
];

export const DROPDOWN_TYPES = {
    PERSON: 'person',
    TYPE: 'type'
};

export function getSignerColor(order: number): string {
    const index = (order - 1) % SIGNER_COLOR_POOL.length;
    return SIGNER_COLOR_POOL[index];
}

export function hexToRgba(hex: string, alpha: number): string {
    const cleanHex = hex.replace('#', '');
    const r = parseInt(cleanHex.substring(0, 2), 16);
    const g = parseInt(cleanHex.substring(2, 4), 16);
    const b = parseInt(cleanHex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getSignerForLabel(
    label: LabelProtoDTO,
    signers: SignerProtoDTO[]
): SignerProtoDTO | null {
    const orderVal = (label as any).signerOrder;
    if (orderVal == null) return null;
    return signers.find((x) => x.order === orderVal) || null;
}

export function getSignerLabelByOrder(
    order: number | null,
    signers: SignerProtoDTO[],
    profileMap: Record<string | number, ProfileDTO>
): string {
    if (order == null) return 'No signer';
    const found = signers.find((s) => s.order === order);
    if (!found) return 'Unknown order ' + order;
    const prof = profileMap[found.id as number];
    const name = prof && prof.name ? prof.name : 'NoName';
    return `${found.order} • ${name}`;
}

export function getLabelDisplayName(
    label: LabelProtoDTO,
    signers: SignerProtoDTO[],
    profileMap: Record<string | number, ProfileDTO>
): string {
    if (label.type === 'DATE') {
        return '';
    } else {
        const signer = getSignerForLabel(label, signers);
        if (!signer) {
            return 'Unassigned';
        }
        const prof = profileMap[signer.id as number];
        if (!prof || !prof.name) {
            return 'Unknown';
        }
        return prof.name.slice(0, 80);
    }
}

export function getLabelStyle(
    label: LabelProtoDTO,
    signers: SignerProtoDTO[],
    pageWrapper: HTMLElement | null
): Record<string, string> {
    if (!pageWrapper) {
        return {};
    }

    const rect = pageWrapper.getBoundingClientRect();
    const containerWidth = rect.width;
    const containerHeight = rect.height;

    const labelPxHeight = containerHeight * (label.relativeHeight || 0);
    let ratio = 2;
    let border = 2;
    if (label.type === 'DATE') {
        ratio = 4;
        border = 0;
    }

    const labelPxWidth = labelPxHeight * ratio;
    const left = (label.relativePosX || 0) * containerWidth;
    const top = (label.relativePosY || 0) * containerHeight;
    const signer = getSignerForLabel(label, signers);
    const isUnassigned = (label as any).signerOrder === null;
    const color = isUnassigned ? '#bc5e5e' : (signer ? getSignerColor(signer.order as number) : '#999');
    const bg = label.type === 'DATE' ? color : hexToRgba(color, 0.3);

    const borderStyle = isUnassigned ? 'dashed' : 'solid';

    return {
        position: 'absolute',
        border: `${border}px ${borderStyle} ${color}`,
        backgroundColor: bg,
        width: labelPxWidth + 'px',
        height: labelPxHeight + 'px',
        left: left + 'px',
        top: top + 'px',
        cursor: 'move',
        boxSizing: 'border-box'
    };
}

export function getLabelTextStyle(
    label: LabelProtoDTO,
    signers: SignerProtoDTO[],
    pageWrapper: HTMLElement | null
): Record<string, string> {
    const signer = getSignerForLabel(label, signers);
    const color = signer ? getSignerColor(signer.order as number) : '#bc5e5e';

    if (label.type === 'DATE') {
        let fontSize = '12px';

        if (pageWrapper) {
            const rect = pageWrapper.getBoundingClientRect();
            const containerHeight = rect.height;
            const labelHeight = containerHeight * (label.relativeHeight || 0);
            fontSize = `${Math.max(5, Math.floor(labelHeight * 0.6))}px`;
        }

        return {
            color: '#fff',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0',
            fontSize,
            whiteSpace: 'nowrap'
        };
    } else {
        return {
            background: color,
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '4px'
        };
    }
}

export function reindexSigners(
    payload: { signers: SignerProtoDTO[] },
    allLabels: LabelProtoDTO[],
    oldSigners: SignerProtoDTO[]
): void {

    const orderToLabels = new Map<number, LabelProtoDTO[]>();

    allLabels.forEach(label => {
        const signerOrder = (label as any).signerOrder;
        if (signerOrder !== null) {
            if (!orderToLabels.has(signerOrder)) {
                orderToLabels.set(signerOrder, []);
            }
            orderToLabels.get(signerOrder)?.push(label);
        }
    });


    const orderMapping = new Map<number, number>();

    oldSigners.forEach(oldSigner => {
        const newSignerIndex = payload.signers.findIndex(newSigner => {
            return newSigner.id === oldSigner.id &&
                isSameSignerInstance(newSigner, oldSigner);
        });

        if (newSignerIndex !== -1) {
            orderMapping.set(oldSigner.order as number, newSignerIndex + 1); // +1 т.к. порядок начинается с 1
        }
    });

    payload.signers.forEach((signer, index) => {
        signer.order = index + 1;
    });

    for (let i = allLabels.length - 1; i >= 0; i--) {
        const label = allLabels[i];
        const oldOrder = (label as any).signerOrder;

        if (oldOrder === null) {
            continue;
        }

        if (orderMapping.has(oldOrder)) {
            (label as any).signerOrder = orderMapping.get(oldOrder);
        } else {
            allLabels.splice(i, 1);
        }
    }
}

function isSameSignerInstance(newSigner: SignerProtoDTO, oldSigner: SignerProtoDTO): boolean {
    if ((newSigner as any).originalOrder !== undefined && (oldSigner as any).originalOrder !== undefined) {
        return (newSigner as any).originalOrder === (oldSigner as any).originalOrder;
    }
    return newSigner.order === oldSigner.order;
}

export function hasUnassignedLabels(allLabels: LabelProtoDTO[]): boolean {
    if (!allLabels || allLabels.length === 0) {
        return false;
    }

    return allLabels.some(label => (label as any).signerOrder === null);
}

export function getUnassignedLabelsCount(allLabels: LabelProtoDTO[]): number {
    if (!allLabels || allLabels.length === 0) {
        return 0;
    }

    return allLabels.filter(label => (label as any).signerOrder === null).length;
}

export function preparePayloadForSending(
    payload: { signers: SignerProtoDTO[] },
    allLabels: LabelProtoDTO[]
): boolean {
    if (hasUnassignedLabels(allLabels)) {
        return false;
    }

    payload.signers.forEach(signer => {
        signer.labels = [];
    });

    allLabels.forEach(lb => {
        const signerOrder = (lb as any).signerOrder;
        if (signerOrder != null) {
            const s = payload.signers.find(x => x.order === signerOrder);
            if (s) {
                s.labels.push(lb);
            }
        }
    });

    return true;
}

export function validatePayloadBeforeSending(
    payload: { signers: SignerProtoDTO[] },
    allLabels: LabelProtoDTO[]
): {
    valid: boolean,
    errors: string[]
} {
    const result = {
        valid: true,
        errors: [] as string[]
    };

    const unassignedCount = getUnassignedLabelsCount(allLabels);
    if (unassignedCount > 0) {
        result.valid = false;
        result.errors.push(`There are ${unassignedCount} unassigned label(s) in the document`);
    }

    if (payload.signers.length === 0) {
        result.valid = false;
        result.errors.push('No signers have been added to the document');
    }

    return result;
}

export function validateSignersHaveSignatures(
    payload: { signers: SignerProtoDTO[] },
    allLabels: LabelProtoDTO[]
): {
    valid: boolean,
    errors: string[]
} {
    const result = {
        valid: true,
        errors: [] as string[]
    };

    const signerHasSignature: Record<number, boolean> = {};

    payload.signers.forEach(signer => {
        signerHasSignature[signer.order as number] = false;
    });

    allLabels.forEach(label => {
        const signerOrder = (label as any).signerOrder;
        if (signerOrder != null && label.type === 'SIGNATURE') {
            signerHasSignature[signerOrder] = true;
        }
    });

    const signersWithoutSignature = payload.signers.filter(signer => !signerHasSignature[signer.order as number]);

    if (signersWithoutSignature.length > 0) {
        result.valid = false;

        signersWithoutSignature.forEach(signer => {
            result.errors.push(`Signer #${signer.order} has no SIGNATURE label assigned`);
        });
    }

    return result;
}

export function validateCompletePayload(
    payload: CreateCustomSignableDocumentPayload,
    allLabels: LabelProtoDTO[]
): {
    valid: boolean,
    errors: string[]
} {
    const basicValidation = validatePayloadBeforeSending(payload, allLabels);
    const signatureValidation = validateSignersHaveSignatures(payload, allLabels);

    return {
        valid: basicValidation.valid && signatureValidation.valid,
        errors: [...basicValidation.errors, ...signatureValidation.errors]
    };
}

export function signerHasSignature(
    signerOrder: number,
    allLabels: LabelProtoDTO[]
): boolean {
    return allLabels.some(label =>
        (label as any).signerOrder === signerOrder &&
        label.type === 'SIGNATURE'
    );
}

export function getSignerSignatureStatus(
    payload: { signers: SignerProtoDTO[] },
    allLabels: LabelProtoDTO[]
): Record<number, boolean> {
    const result: Record<number, boolean> = {};

    payload.signers.forEach(signer => {
        result[signer.order as number] = signerHasSignature(signer.order as number, allLabels);
    });

    return result;
}

export function getTypeLabel(typeVal: string | null, typeOptions: Array<{ value: string, label: string }>): string {
    if (!typeVal) {
        return 'Choose type...';
    }
    const found = typeOptions.find((t) => t.value === typeVal);
    return found ? found.label : 'Unknown';
}

export function toggleDropdown(
    globalIndex: number,
    dropdownType: 'person' | 'type',
    personOpenMap: Record<number, boolean>,
    typeOpenMap: Record<number, boolean>
): {
    personOpenMap: Record<number, boolean>,
    typeOpenMap: Record<number, boolean>
} {
    const newPersonOpenMap = { ...personOpenMap };
    const newTypeOpenMap = { ...typeOpenMap };

    if (dropdownType === 'person') {
        newTypeOpenMap[globalIndex] = false;
        newPersonOpenMap[globalIndex] = !personOpenMap[globalIndex];
    } else {
        newPersonOpenMap[globalIndex] = false;
        newTypeOpenMap[globalIndex] = !typeOpenMap[globalIndex];
    }

    return { personOpenMap: newPersonOpenMap, typeOpenMap: newTypeOpenMap };
}

export interface DraggingState {
    dragging: boolean;
    resizing: boolean;
    justDragged: boolean;
    isPotentialDrag?: boolean;
    currentLabelIndex?: number | null;
    initialClickX?: number;
    initialClickY?: number;
    dragOffsetX?: number;
    dragOffsetY?: number;
    dragThreshold?: number;
    resizeCorner?: string | null;
}

export function initDraggingState(): DraggingState {
    return {
        dragging: false,
        resizing: false,
        justDragged: false,
        isPotentialDrag: false,
        currentLabelIndex: null,
        initialClickX: 0,
        initialClickY: 0,
        dragOffsetX: 0,
        dragOffsetY: 0,
        dragThreshold: 5,
        resizeCorner: null
    };
}

export function startDrag(
    event: MouseEvent,
    globalIndex: number,
    state: DraggingState
): DraggingState {
    const newState = { ...state };

    newState.initialClickX = event.clientX;
    newState.initialClickY = event.clientY;

    const labelElement = event.currentTarget as HTMLElement;
    const rect = labelElement.getBoundingClientRect();
    newState.dragOffsetX = event.clientX - rect.left;
    newState.dragOffsetY = event.clientY - rect.top;

    return newState;
}

export function startResize(
    event: MouseEvent,
    globalIndex: number,
    corner: string,
    state: DraggingState
): DraggingState {
    const newState = { ...state };

    newState.resizing = true;
    newState.currentLabelIndex = globalIndex;
    newState.resizeCorner = corner;
    newState.justDragged = false;

    return newState;
}

export function trackPotentialDrag(globalIndex: number, state: DraggingState): DraggingState {
    const newState = { ...state };

    newState.isPotentialDrag = true;
    newState.currentLabelIndex = globalIndex;

    return newState;
}

export function handleMouseMove(
    event: MouseEvent,
    state: DraggingState,
    allLabels: LabelProtoDTO[]
): { state: DraggingState, allLabels: LabelProtoDTO[] } {
    const newState = { ...state };
    const newLabels = [...allLabels];

    if (newState.isPotentialDrag && newState.currentLabelIndex != null) {
        const xDiff = Math.abs(event.clientX - (newState.initialClickX || 0));
        const yDiff = Math.abs(event.clientY - (newState.initialClickY || 0));

        if (xDiff > (newState.dragThreshold || 5) || yDiff > (newState.dragThreshold || 5)) {
            newState.dragging = true;
            newState.isPotentialDrag = false;
        }
    }

    if (newState.dragging && newState.currentLabelIndex != null) {
        const label = newLabels[newState.currentLabelIndex];
        const pageWrapper = document.querySelector(
            `.page-wrapper[data-page-number='${label.pageNumber}']`
        ) as HTMLElement;

        if (!pageWrapper) return { state: newState, allLabels: newLabels };

        const rect = pageWrapper.getBoundingClientRect();
        const newLeft = event.clientX - rect.left - (newState.dragOffsetX || 0);
        const newTop = event.clientY - rect.top - (newState.dragOffsetY || 0);

        label.relativePosX = newLeft / rect.width;
        label.relativePosY = newTop / rect.height;
    }

    if (newState.resizing && newState.currentLabelIndex != null) {
        const label = newLabels[newState.currentLabelIndex];
        const pageWrapper = document.querySelector(
            `.page-wrapper[data-page-number='${label.pageNumber}']`
        ) as HTMLElement;

        if (!pageWrapper) return { state: newState, allLabels: newLabels };

        const rect = pageWrapper.getBoundingClientRect();
        const deltaY = event.movementY;
        const deltaRelative = deltaY / rect.height;
        label.relativeHeight = Math.max(0.001, (label.relativeHeight || 0) + deltaRelative);
    }

    return { state: newState, allLabels: newLabels };
}

export function handleMouseUp(state: DraggingState, toggleToolbarFunc?: (index: number) => void): DraggingState {
    const newState = { ...state };

    if (newState.isPotentialDrag && newState.currentLabelIndex !== null && !newState.dragging) {
        if (toggleToolbarFunc) {
            toggleToolbarFunc(newState.currentLabelIndex as number);
        }
    }

    newState.dragging = false;
    newState.resizing = false;
    newState.isPotentialDrag = false;
    newState.currentLabelIndex = null;
    newState.resizeCorner = null;

    newState.justDragged = newState.dragging || newState.resizing;

    if (newState.justDragged) {
        setTimeout(() => {
            if (state.justDragged) {
                state.justDragged = false;
            }
        }, 80);
    }

    return newState;
}

export function createNewLabel(
    event: MouseEvent,
    pageNumber: number,
    pageWrapper: HTMLElement
): LabelProtoDTO {
    const rect = pageWrapper.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    const newLabel = new LabelProtoDTO();
    (newLabel as any).signerOrder = null;
    newLabel.type = SigningLabelType.SIGNATURE;
    newLabel.pageNumber = pageNumber;
    newLabel.relativeHeight = 0.07;
    newLabel.relativePosX = offsetX / rect.width;
    newLabel.relativePosY = offsetY / rect.height;

    return newLabel;
}

export interface ModalActions {
    showProfileSearch: () => void;
    hideProfileSearch: () => void;
    showOrderConfirmation: () => void;
    hideOrderConfirmation: () => void;
}