







































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import CreateCustomSignableDocumentPayload, { LabelProtoDTO } from '@/dto/signature/CreateCustomSignableDocumentPayload'
import ProfileDTO from '@/dto/profile/ProfileDTO'
import SingleFileHolder from '@/components/util/SingleFileHolder.vue'
import PortalInput from "@/components/common/PortalInput.vue"
import * as signingUtils from '@/utils/SigningUtils'

@Component({
  components: {
    SingleFileHolder,
    PortalInput
  }
})
export default class CustomDocumentEditorPanel extends Vue {
  @Prop() readonly payload!: CreateCustomSignableDocumentPayload
  @Prop() readonly pages!: Array<{ pageNumber: number; src: string }>
  @Prop() readonly allLabels!: LabelProtoDTO[]
  @Prop() readonly toolbarOpenIndex!: number | null
  @Prop() readonly personOpenMap!: Record<number, boolean>
  @Prop() readonly typeOpenMap!: Record<number, boolean>
  @Prop() readonly profileMap!: Record<string | number, ProfileDTO>
  @Prop() readonly typeOptions!: Array<{ value: string, label: string }>
  @Prop() readonly corners!: string[]
  @Prop() readonly draggingState!: signingUtils.DraggingState

  signingUtils = signingUtils

  handlePageClick(e: MouseEvent, pageNumber: number) {
    this.$emit('page-click', e, pageNumber)
  }

  handleLabelMouseDown(e: MouseEvent, globalIndex: number) {
    const target = e.target as HTMLElement;
    if (
        target.closest('.label-toolbar') ||
        target.closest('.dropdown-person') ||
        target.closest('.dropdown-type') ||
        target.closest('.dropdown-person-menu') ||
        target.closest('.dropdown-type-menu') ||
        target.closest('.delete-icon') ||
        target.closest('.resize-handle')
    ) {
      return;
    }

    this.$emit('label-drag-start', e, globalIndex);
    this.$emit('track-potential-click', globalIndex);
  }

  getLabelsForPageIndex(pageNumber: number) {
    return this.allLabels
        .map((lb, i) => ({ label: lb, globalIndex: i }))
        .filter((obj) => obj.label.pageNumber === pageNumber)
  }

  isLabelUnassigned(label: LabelProtoDTO): boolean {
    return (label as any).signerOrder === null;
  }

  getPageWrapper(pageNumber: number): HTMLElement | null {
    return document.querySelector(
        `.page-wrapper[data-page-number='${pageNumber}']`
    ) as HTMLElement;
  }

  getLabelStyle(label: LabelProtoDTO, pageNumber: number) {
    const pageWrapper = this.getPageWrapper(pageNumber);

    const style = signingUtils.getLabelStyle(label, this.payload.signers, pageWrapper);
    return {
      ...style,
      zIndex: this.toolbarOpenIndex !== null &&
      this.allLabels[this.toolbarOpenIndex] === label ? '100' : '10'
    }
  }

  getLabelColor(label: LabelProtoDTO) {
    const signer = signingUtils.getSignerForLabel(label, this.payload.signers)
    if (!signer) {
      return '#bc5e5e'
    }
    return signingUtils.getSignerColor(signer.order as number)
  }

  getSignerLabel(label: LabelProtoDTO) {
    const s = signingUtils.getSignerForLabel(label, this.payload.signers)
    if (!s) return 'No signer selected...'
    return signingUtils.getSignerLabelByOrder(s.order, this.payload.signers, this.profileMap)
  }
}
