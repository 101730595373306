

















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import CreateCustomSignableDocumentPayload, { LabelProtoDTO } from '@/dto/signature/CreateCustomSignableDocumentPayload'
import ProfileDTO from '@/dto/profile/ProfileDTO'
import draggable from 'vuedraggable'
import * as signingUtils from '@/utils/SigningUtils'
import {WorkspaceType} from "@/dto/auth/Workspace";
import SingleFileHolder from '@/components/util/SingleFileHolder.vue'

@Component({
  components: {
    draggable,
    SingleFileHolder
  }
})
export default class CustomDocumentSignersPanel extends Vue {
  @Prop() readonly payload!: CreateCustomSignableDocumentPayload
  @Prop() readonly profileMap!: Record<string | number, ProfileDTO>
  @Prop() readonly allLabels!: LabelProtoDTO[]

  signingUtils = signingUtils
  WorkspaceType = WorkspaceType

  get hasUnassignedLabels(): boolean {
    return signingUtils.hasUnassignedLabels(this.allLabels);
  }

  get unassignedLabelsCount(): number {
    return signingUtils.getUnassignedLabelsCount(this.allLabels);
  }
}
