




















































































































import {namespace} from 'vuex-class'
import {Component, Vue, Watch} from 'vue-property-decorator'
import CreateCustomSignableDocumentPayload, {
  LabelProtoDTO,
  SignerProtoDTO,
} from '@/dto/signature/CreateCustomSignableDocumentPayload'
import ProfileDTO from '@/dto/profile/ProfileDTO'
import FileMetaDTO from '@/dto/files/FileMetaDTO'
import SignerDTO from '@/dto/signature/SignerDTO'
import FileService from '@/services/FileService'
import SignatureService from '@/services/SignatureService'
import SimpleProfileSearch from '@/components/common/SimpleProfileSearch.vue'
import {processError} from '@/utils/ComponentUtils'
import {FILE_PUBLISH_DOCUMENT_ENDPOINT} from '@/constants/endpoints'
import authHeader from '@/services/auth-header'
import draggable from 'vuedraggable'
import CustomDocumentEditorPanel from "@/components/documents/signing/custom/CustomDocumentEditorPanel.vue";
import CustomDocumentSignersPanel from "@/components/documents/signing/custom/CustomDocumentSignersPanel.vue";
import * as signingUtils from '@/utils/SigningUtils'

const AppModule = namespace('App')

@Component({
  components: {
    CustomDocumentSignersPanel,
    CustomDocumentEditorPanel,
    SimpleProfileSearch,
    draggable
  }
})
export default class CustomSigningDocumentBuilder extends Vue {
  @AppModule.Action private startLoading!: () => void
  @AppModule.Action private stopLoading!: () => void

  payload: CreateCustomSignableDocumentPayload = new CreateCustomSignableDocumentPayload()
  profileMap: Record<string | number, ProfileDTO> = {}
  pages: Array<{ pageNumber: number; src: string }> = []
  allLabels: LabelProtoDTO[] = []

  corners = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
  personOpenMap: Record<number, boolean> = {}
  typeOpenMap: Record<number, boolean> = {}
  typeOptions = signingUtils.LABEL_TYPES
  draggingState = signingUtils.initDraggingState()
  dragStartSigners: SignerProtoDTO[] = []
  toolbarOpenIndex: number | null = null
  signingUtils = signingUtils

  get hasUnassignedLabels(): boolean {
    return signingUtils.hasUnassignedLabels(this.allLabels);
  }

  get hasMissingSignatures(): boolean {
    return this.payload.signers.some(signer =>
        !signingUtils.signerHasSignature(signer.order as number, this.allLabels)
    );
  }

  get hasValidationErrors(): boolean {
    return this.hasUnassignedLabels || this.hasMissingSignatures;
  }

  mounted() {
    if (this.payload.file) {
      this.loadFilePages(this.payload.file)
    }
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
    document.addEventListener('click', this.onGlobalClick)
  }

  beforeDestroy() {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
    document.removeEventListener('click', this.onGlobalClick)
  }

  @Watch('payload.file')
  onFileChanged(newFile: FileMetaDTO | SignerDTO | null) {
    if (newFile) {
      this.loadFilePages(newFile)
    } else {
      this.pages = []
      this.allLabels = []
    }
  }

  async loadFilePages(file: FileMetaDTO | SignerDTO) {
    this.startLoading()
    try {
      if (this.isFileMetaDTO(file) && file.extension !== 'pdf') {
        const res = await FileService.publishDocument(file.id)
        this.pages = [
          {
            pageNumber: 1,
            src: `${FILE_PUBLISH_DOCUMENT_ENDPOINT}/${res.data}`
          }
        ]
      } else {
        const internalKey = this.isFileMetaDTO(file)
            ? file.internalKey
            : file.document.displayedFile.internalKey
        const resp = await FileService.getSplitFile(internalKey)
        const splitFile = resp.data

        this.pages = []
        const token = authHeader().Authorization
        for (const p of splitFile.pages) {
          const r = await FileService.publishDocument(p.pageFile.id)
          const src = `${FILE_PUBLISH_DOCUMENT_ENDPOINT}/${r.data}${
              token ? '?token=' + token : ''
          }`
          this.pages.push({pageNumber: p.pageNumber, src})
        }
        this.pages.sort((a, b) => a.pageNumber - b.pageNumber)
      }
    } catch (error) {
      processError(error, this)
    } finally {
      this.stopLoading()
    }
  }

  private isFileMetaDTO(file: FileMetaDTO | SignerDTO): file is FileMetaDTO {
    return (file as FileMetaDTO).extension !== undefined
  }

  onProfileSelect(profile: ProfileDTO) {
    this.addSigner(profile)
    this.$modal.hide('profileSearch')
  }

  addSigner(profile: ProfileDTO) {
    const newSigner: SignerProtoDTO = {
      id: profile.id,
      type: profile.type,
      order: this.payload.signers.length + 1,
      labels: []
    }
    this.profileMap[profile.id] = profile
    this.payload.signers.push(newSigner)
  }

  openProfilesModal() {
    this.$modal.show('profileSearch')
  }

  openOrderConfirmationModal(): void {
    if (this.hasUnassignedLabels) {
      processError("There are unassigned labels in the document. Please assign all labels before sending.", this);
      return;
    }
    this.$modal.show("orderConfirmation");
  }

  closeOrderConfirmationModal(): void {
    this.$modal.hide("orderConfirmation");
    this.draggingState = signingUtils.initDraggingState();
  }

  onPageClick(e: MouseEvent, pageNumber: number) {
    if (e.button !== 0 || this.draggingState.justDragged || this.draggingState.dragging || this.draggingState.resizing) {
      return
    }

    const target = e.target as HTMLElement
    if (
        target.closest('.label-overlay') ||
        target.closest('.label-toolbar') ||
        target.closest('.resize-handle')
    ) {
      return
    }

    const pageWrapper = e.currentTarget as HTMLElement
    if (!pageWrapper) return

    const newLabel = signingUtils.createNewLabel(e, pageNumber, pageWrapper)
    this.allLabels.push(newLabel)
  }

  toggleLabelToolbar(globalIndex: number) {
    if (this.draggingState.dragging || this.draggingState.resizing) {
      return;
    }

    this.toolbarOpenIndex = (this.toolbarOpenIndex === globalIndex) ? null : globalIndex;

    this.personOpenMap = {};
    this.typeOpenMap = {};
  }

  toggleDropdown(globalIndex: number, type: 'person' | 'type') {
    const result = signingUtils.toggleDropdown(globalIndex, type, this.personOpenMap, this.typeOpenMap);
    this.personOpenMap = result.personOpenMap;
    this.typeOpenMap = result.typeOpenMap;
  }

  toggleDropdownPerson(globalIndex: number) {
    this.toggleDropdown(globalIndex, 'person');
  }

  toggleDropdownType(globalIndex: number) {
    this.toggleDropdown(globalIndex, 'type');
  }

  pickSignerOrder(globalIndex: number, newSignerOrder: number | null) {
    const lbl = this.allLabels[globalIndex]
    ;(lbl as any).signerOrder = newSignerOrder
    this.personOpenMap[globalIndex] = false
  }

  pickType(globalIndex: number, typeVal: string) {
    const label = this.allLabels[globalIndex];
    label.type = typeVal as any;

    label.relativeHeight = typeVal === 'DATE' ? 0.017 : 0.07;
    this.typeOpenMap[globalIndex] = false;
  }

  onGlobalClick(e: MouseEvent) {
    if (this.draggingState.dragging || this.draggingState.resizing || this.draggingState.justDragged) {
      return;
    }

    const target = e.target as HTMLElement
    if (
        !target.closest('.dropdown-person') &&
        !target.closest('.dropdown-person-menu') &&
        !target.closest('.dropdown-type') &&
        !target.closest('.dropdown-type-menu') &&
        !target.closest('.label-overlay')
    ) {
      this.toolbarOpenIndex = null
      this.personOpenMap = {}
      this.typeOpenMap = {}
    }
  }

  removeLabel(globalIndex: number) {
    this.allLabels.splice(globalIndex, 1)
  }

  removeSigner(sIndex: number) {
    const removedSigner = this.payload.signers[sIndex]
    this.payload.signers.splice(sIndex, 1)

    const removedOrder = removedSigner.order
    this.allLabels = this.allLabels.filter((label) => {
      return (label as any).signerOrder !== removedOrder
    })

    const oldSigners = this.dragStartSigners.length
        ? this.dragStartSigners
        : this.payload.signers.map((s) => ({...s}))

    signingUtils.reindexSigners(this.payload, this.allLabels, oldSigners)
  }

  trackPotentialClick(globalIndex: number) {
    const newState = signingUtils.trackPotentialDrag(globalIndex, this.draggingState);
    Object.assign(this.draggingState, newState);
  }

  startDrag(e: MouseEvent, globalIndex: number) {
    const newState = signingUtils.startDrag(e, globalIndex, this.draggingState);
    Object.assign(this.draggingState, newState);
  }

  startResize(e: MouseEvent, globalIndex: number, corner: string) {
    const newState = signingUtils.startResize(e, globalIndex, corner, this.draggingState);
    Object.assign(this.draggingState, newState);
  }

  onMouseMove(e: MouseEvent) {
    const result = signingUtils.handleMouseMove(e, this.draggingState, this.allLabels);

    if (result.allLabels !== this.allLabels) {
      this.allLabels = result.allLabels;
    }

    Object.assign(this.draggingState, result.state);
  }

  onMouseUp(e: MouseEvent) {
    const newState = signingUtils.handleMouseUp(this.draggingState, this.toggleLabelToolbar.bind(this));
    Object.assign(this.draggingState, newState);
  }

  goBack() {
    this.$router.push({
      name: this.$routeNames.ARCHIVE_SIGNABLE_LIST_NEW,
      params: this.$route.params
    })
  }

  onDragStart() {
    this.dragStartSigners = this.payload.signers.map((s) => ({...s}))
  }

  onDragEnd() {
    const oldSigners = this.dragStartSigners
    signingUtils.reindexSigners(this.payload, this.allLabels, oldSigners)
    this.dragStartSigners = []
  }

  get validationResults(): { valid: boolean; errors: string[] } {
    return signingUtils.validateCompletePayload(this.payload, this.allLabels);
  }

  sendPayload(): void {
    const validationResult = signingUtils.validateCompletePayload(this.payload, this.allLabels);

    if (!validationResult.valid) {
      this.closeOrderConfirmationModal();

      validationResult.errors.forEach(error => {
        processError(error, this)
      });
      return;
    }

    signingUtils.preparePayloadForSending(this.payload, this.allLabels);

    this.$validator.validateAll().then(isValid => {
      if (isValid) {
        this.startLoading();
        SignatureService.buildCustomDocument(this.payload)
            .then(
                () => {
                  this.stopLoading();
                  this.$router.push({name: this.$routeNames.ARCHIVE_SIGNABLE_LIST_NEW});
                },
                err => {
                  this.stopLoading();
                  this.closeOrderConfirmationModal();
                  processError(err, this);
                }
            );
      } else {
        this.closeOrderConfirmationModal();
      }
    });
  }
}
